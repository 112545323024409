import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import Helmet from "react-helmet"

import "typeface-roboto"
import "typeface-poppins"

const colors = {
  white: "#ffffff",
  offwhite: "#929292",
  charcoal: "#36454f",
  black: "#111",
  black1: "#4A4A4C",
  black2: "#2b2b3a",
  black3: "#121212",
  black4: "#1b1b1b",
  blue: "#003A8D",
  lightblue: "#5e83b7",
  greyblue: "#5279b1",
  blue2: "#194d98",
  blue3: "#2A449A",
  darkgrey: "#333333",
  grey: "#707070",
  grey2: "#818181",
  lightgrey5: "#9a9a9a",
  lightgrey4: "#c0c0c0",
  lightgrey3: "#DDDDDD",
  lightgrey2: "#F2F2F2",
  lightgrey1: "#F6F6F6",
  yellow: "#FFD800",
  darkyellow: "#FFCB00",
  red: "#FF0000",
  // display message colors
  success: {
    fontColor: "#fff",
    light: "#12B000",
    dark: "#15C05F",
  },
  info: {
    fontColor: "#fff",
    light: "#1FB6FF",
    dark: "#1CABF1",
  },
  warning: {
    fontColor: "rgba(0,0,0,0.7)",
    light: "#FFC82C",
    dark: "#F3BE26",
  },
  danger: {
    fontColor: "#fff",
    light: "#FF4949",
    dark: "#EF3C3C",
  },
}

export const windowWidths = {
  desktopHD: 1920,
  desktopSmall: 1240,
  desktopStandard: 1366,
  tabletLarge: 1024,
  tabletSmall: 767,
  mobileLarge: 480,
  mobileStandard: 375,
}

const theme = {
  boxShadow: "0px 1px 2px #00000029",
  maxWidth: `${windowWidths.desktopSmall}px`,
  breakLarge: `${windowWidths.desktopSmall}px`,
  breakMedium: `${windowWidths.tabletLarge}px`,
  breakSmall: `${windowWidths.tabletSmall}px`,
  breakTiny: `${windowWidths.mobileLarge}px`,
  breakXTiny: `${windowWidths.mobileStandard}px`,
  headingPrimaryFont: "Poppins, Roboto, Arial, sans-serif",
  headingSecondaryFont: "Poppins, Roboto, Cambria, serif",
  headingColor: colors.black4,
  bodyFont: "Poppins, Roboto, Arial, sans-serif",
  bodyColor: colors.black1,
  colors,
}

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 1em;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    font-family: ${theme.bodyFont};
  }

  body {
    padding: 0;
    margin: 0;
    color: ${theme.bodyColor};
    font-family: ${theme.bodyFont};
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    font-style: normal;  
    -webkit-overflow-scrolling: touch;  
  }

  body.menu-fixed,
  body.drawer-fixed {
    overflow: hidden;
    width:100%;
    position: fixed;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: ${theme.headingPrimaryFont};
    color: ${theme.headingColor};
    &.uppercase {
      text-transform: uppercase;
    }
    &.centered {
      text-align: center;
    }
  }

  h1 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.47;
    font-style: normal;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.47;
    font-style: normal;
    text-transform: uppercase;
  }

  h2.home-page {
    font-weight: 900;
  }

  h2.light {
    font-weight: 400;
    font-size: 1.777rem;
    line-height: 2;
  }

  h3 {
    font-size: 1.333rem;
    font-weight: 400;
    line-height: 1.67;
    font-style: normal;
    text-transform: uppercase;
  }

  h4 {
    font-size: 1.777rem;
    font-weight: 700;
    line-height: 1.67;
    font-style: normal;
    text-transform: uppercase;
  }

  h5 {
    font-size: 1.333rem;
    font-weight: 700;
    line-height: 1.2;
    font-style: normal;
    text-transform: uppercase;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    font-style: normal;
  }

  p {
    color: ${theme.bodyColor};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.9;
    font-style: normal;
    margin-top: 1.8rem;
    &.mega{
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.5;
      font-style: normal;
    }
    &.large{
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.5;
      font-style: normal;
    }
    &.small{
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      font-style: normal;
    }
    &.tiny{
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      font-style: normal;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height:auto;
  }

  table {
    border: 1px solid ${theme.colors.painswick};
    border-collapse: collapse;
  }

  table td {
    border: 1px solid ${theme.colors.painswick};
    padding: 20px 30px;
  }

  table tr:nth-child(odd) {
    background: ${theme.colors.cloud};
  }

  .ReactModal__Overlay {
		opacity: 0;
		transition: opacity 400ms ease-in-out;
    z-index: 1;
	}

	.ReactModal__Overlay--after-open {
		opacity: 1;
	}

	.ReactModal__Overlay--before-close {
		opacity: 0;
	}

  .ReactModal__Content{
    width: 600px;
    @media (max-width: ${theme.breakSmall}) {
      width: 350px;
    }
  }
  
  button, a.button-class{
    display: block;
    -webkit-appearance: none;
    line-height: 1.2rem;
    border: 1px solid ${theme.colors.lightgrey3};
    background-color: ${theme.colors.lightgrey3};
    border-radius: 50px;
    outline: none;
    color: ${theme.colors.black1};
    text-align: center;
    padding: 1rem 3rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover, &.button-active {
      border: 1px solid ${theme.colors.grey};
      background-color: ${theme.colors.grey};
      color: white;
      transition: all 0.2s ease-in-out;
    }
    @media (max-width: ${theme.breakMedium}) {
      width: 100%;
    }
  }  
`

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet key="createGlobalStyle" />
      <GlobalStyle />
      <>{children}</>
    </ThemeProvider>
  )
}

export default ThemeConfig
