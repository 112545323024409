exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-our-team-jsx": () => import("./../../../src/pages/about-us/our-team.jsx" /* webpackChunkName: "component---src-pages-about-us-our-team-jsx" */),
  "component---src-pages-area-calculator-jsx": () => import("./../../../src/pages/area-calculator.jsx" /* webpackChunkName: "component---src-pages-area-calculator-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-delivery-jsx": () => import("./../../../src/pages/checkout/delivery.jsx" /* webpackChunkName: "component---src-pages-checkout-delivery-jsx" */),
  "component---src-pages-checkout-index-jsx": () => import("./../../../src/pages/checkout/index.jsx" /* webpackChunkName: "component---src-pages-checkout-index-jsx" */),
  "component---src-pages-checkout-payment-jsx": () => import("./../../../src/pages/checkout/payment.jsx" /* webpackChunkName: "component---src-pages-checkout-payment-jsx" */),
  "component---src-pages-checkout-summary-jsx": () => import("./../../../src/pages/checkout/summary.jsx" /* webpackChunkName: "component---src-pages-checkout-summary-jsx" */),
  "component---src-pages-completed-projects-jsx": () => import("./../../../src/pages/completed-projects.jsx" /* webpackChunkName: "component---src-pages-completed-projects-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-my-account-billing-details-jsx": () => import("./../../../src/pages/my-account/billing-details.jsx" /* webpackChunkName: "component---src-pages-my-account-billing-details-jsx" */),
  "component---src-pages-my-account-index-jsx": () => import("./../../../src/pages/my-account/index.jsx" /* webpackChunkName: "component---src-pages-my-account-index-jsx" */),
  "component---src-pages-my-account-order-history-jsx": () => import("./../../../src/pages/my-account/order-history.jsx" /* webpackChunkName: "component---src-pages-my-account-order-history-jsx" */),
  "component---src-pages-my-account-shipping-details-jsx": () => import("./../../../src/pages/my-account/shipping-details.jsx" /* webpackChunkName: "component---src-pages-my-account-shipping-details-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-returns-policy-jsx": () => import("./../../../src/pages/returns-policy.jsx" /* webpackChunkName: "component---src-pages-returns-policy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-service-jsx": () => import("./../../../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-wishlist-jsx": () => import("./../../../src/pages/wishlist.jsx" /* webpackChunkName: "component---src-pages-wishlist-jsx" */),
  "component---src-template-pages-templates-all-brands-page-jsx": () => import("./../../../src/template-pages/templates/all-brands-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-all-brands-page-jsx" */),
  "component---src-template-pages-templates-blog-category-page-jsx": () => import("./../../../src/template-pages/templates/blog-category-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-blog-category-page-jsx" */),
  "component---src-template-pages-templates-blog-post-page-jsx": () => import("./../../../src/template-pages/templates/blog-post-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-blog-post-page-jsx" */),
  "component---src-template-pages-templates-branch-page-jsx": () => import("./../../../src/template-pages/templates/branch-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-branch-page-jsx" */),
  "component---src-template-pages-templates-product-page-jsx": () => import("./../../../src/template-pages/templates/product-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-product-page-jsx" */),
  "component---src-template-pages-templates-project-view-page-jsx": () => import("./../../../src/template-pages/templates/project-view-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-project-view-page-jsx" */),
  "component---src-template-pages-templates-shop-page-jsx": () => import("./../../../src/template-pages/templates/shop-page.jsx" /* webpackChunkName: "component---src-template-pages-templates-shop-page-jsx" */)
}

