/* eslint-disable no-use-before-define */
import React from "react"
import ProductContext from "./product-context"

const ProductProvider = ({ children }) => {
  return (
    <ProductContext.Provider value={{}}>{children}</ProductContext.Provider>
  )
}

export default ProductProvider
