import React from "react"
import AreaCalcProvider from "./src/context/area-calc/area-calc-provider"
import AuthProvider from "./src/context/auth/auth-provider"
import CartProvider from "./src/context/cart/cart-provider"
import ProductProvider from "./src/context/product/product-provider"
import CheckoutProvider from "./src/context/checkout/checkout-provider"

const RootElementWrapper = ({ element }) => {
  return (
    <AuthProvider>
      <CheckoutProvider>
        <CartProvider>
          <ProductProvider>
            <AreaCalcProvider>{element}</AreaCalcProvider>
          </ProductProvider>
        </CartProvider>
      </CheckoutProvider>
    </AuthProvider>
  )
}

export const wrapRootElement = RootElementWrapper

// This feature does not currently work due to a bug in Gatsby. We can try it on a newer version when we update
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // // On checkout pages, scroll to tabs
  // if (location.pathname.includes("/checkout/")) {
  //   return false
  // }

  return true
}
