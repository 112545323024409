import { kyWrapped } from "../lib/utils"

// **** Get cart contents, meta, shipping
export const getCartRequest = (user, logout = () => {}) => {
  const config = {
    credentials: "include",
    method: "get",
    logout,
  }
  let url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  if (user) {
    if (user.token) {
      config.headers = {
        Authorization: `Bearer ${user.token}`,
      }
    }
  }
  return kyWrapped(url, config)
}

// **** Add to cart API call
export const postCartItemRequest = (data, token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  const config = {
    json: {
      cart_method: "add",
      ...data,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// **** Delete cart item request
export const deleteCartItemRequest = (
  data,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  const config = {
    json: {
      cart_method: "remove",
      ...data,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// **** Update cart item request
export const updateCartItemRequest = (
  data,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  const config = {
    json: {
      cart_method: "update",
      ...data,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// **** Clear cart request
export const clearCartRequest = (token = null, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  const config = {
    json: {
      cart_method: "clear",
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// **** Select shipping method
export const selectShippingMethodRequest = (
  selected_shipping_method,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/cart`
  const config = {
    json: {
      cart_method: "set_shipping",
      selected_shipping_method,
    },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}
