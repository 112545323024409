import { kyWrapped } from "../lib/utils"

// **** Get all the orders belonging to user
export const getAllOrders = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/my-orders`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

// **** Get an order by ID
export const getOrder = (orderId, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/my-orders/${orderId}`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

// **** Get the user address
export const getCustomerAddress = (token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/my-address`
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  })
}

// **** Get the available countries to select
export const getCountries = (token, logout = () => {}, country_code = "") => {
  const cc_url = country_code ? `/${country_code}` : ``
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/countries${cc_url}`
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    logout,
  }
  return kyWrapped(url, config)
}

// **** Update the user address
export const updateCustomerAddress = (address, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/my-address`
  const { ...putBody } = address
  return kyWrapped(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: putBody,
    method: "post",
    logout,
  })
}
