/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import AreaCalcContext from "./area-calc-context"

import { windowWidths } from "../../components/theme/theme-config"

const AreaCalcProvider = ({ children }) => {
  // NEVER use setAreaCalcPopupOpen to open the popup!! Only use openAreaCalcPopup
  const [areaCalcPopupOpen, setAreaCalcPopupOpen] = useState(null)

  const [areaPerBox, setAreaPerBox] = useState(0)
  const [areaCalcProductId, setAreaCalcProductId] = useState(0)
  const [areaCalcPrevUrl, setAreaCalcPrevUrl] = useState(null)

  // this function provides abstraction between external calls
  // to open the popup and the internal functions
  const openAreaCalcPopup = (newProductId, newAreaPerBox) => {
    if (newProductId && newAreaPerBox) {
      setAreaCalcProductId(newProductId)
      setAreaPerBox(newAreaPerBox)
      setAreaCalcPopupOpen(true)
      if (typeof window != "undefined") {
        // If the device has a small screen, navigate to the
        // page for the popup instead of opening the popup
        if (window?.screen?.width < windowWidths.desktopSmall) {
          setAreaCalcPrevUrl(window?.location?.pathname || "/")
          navigate("/area-calculator/", { replace: false })
          return
        }
      }
    } else {
      closeAreaCalcPopup()
      return
    }
  }

  // this function provides abstraction between external calls
  // to close the popup and the internal functions
  const closeAreaCalcPopup = () => setAreaCalcPopupOpen(false)

  // This useEffect looks for changes in areaCalcPopupOpen and
  // clears everything if it is set to false
  useEffect(() => {
    if (!areaCalcPopupOpen || !areaPerBox || !areaCalcProductId) {
      setAreaCalcProductId(0)
      setAreaPerBox(0)
      setAreaCalcPopupOpen(false)

      if (typeof window != "undefined") {
        if (window?.location?.pathname == "/area-calculator/") {
          const navDest = areaCalcPrevUrl ? `${areaCalcPrevUrl}` : -1
          navigate(navDest, { replace: false })
        }
      }
    }
  }, [areaCalcPopupOpen])

  return (
    <AreaCalcContext.Provider
      value={{
        areaPerBox,
        areaCalcProductId,
        areaCalcPopupOpen,
        openAreaCalcPopup,
        closeAreaCalcPopup,
        areaCalcPrevUrl,
        // NEVER use setAreaCalcPopupOpen to open the popup!! Only use openAreaCalcPopup
        setAreaCalcPopupOpen,
      }}
    >
      {children}
    </AreaCalcContext.Provider>
  )
}

export default AreaCalcProvider
