/* eslint-disable no-use-before-define */
import React, { useState } from "react"
import CheckoutContext from "./checkout-context"
import { graphql, useStaticQuery } from "gatsby"

// =====================
// 	  Checkout Context
// =====================
const CheckoutProvider = ({ children }) => {
  // Available payment options to choose from
  const paymentGatewayData = useStaticQuery(PAYMENT_GATEWAY_QUERY)
  const paymentOptions = paymentGatewayData?.seaSiteInfo?.payment_gateways || []

  // Global selected payment methods
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentOptions[0]
  )
  const [availableProvinces, setAvailableProvinces] = useState([])
  const [selectedProvince, setSelectedProvince] = useState("None")
  const [availableBranches, setAvailableBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(0)

  return (
    <CheckoutContext.Provider
      value={{
        paymentOptions,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        selectedBranch,
        setSelectedBranch,
        availableBranches,
        setAvailableBranches,
        availableProvinces,
        setAvailableProvinces,
        selectedProvince,
        setSelectedProvince,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutProvider

// ===============
//      QUERY
// ===============

const PAYMENT_GATEWAY_QUERY = graphql`
  {
    seaSiteInfo {
      payment_gateways {
        description
        label
        paymentCode
      }
    }
  }
`
