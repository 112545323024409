import { kyWrapped } from "../lib/utils"

// **** Add an item to wishlist
export const postWishlistItemRequest = (
  productId,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/wishlist/add`
  const config = {
    json: { product_id: productId },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}

// **** Remove an item from wishlist
export const deleteWishlistItemRequest = (
  productId,
  token = null,
  logout = () => {}
) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/wishlist/remove`
  const config = {
    json: { product_id: productId },
    credentials: "include",
    method: "post",
    logout,
  }
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return kyWrapped(url, config)
}
