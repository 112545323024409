/* eslint-disable no-use-before-define */
import { login, register } from "../../api/auth"
import {
  analyticsLogin,
  analyticsLogout,
  analyticsRegister,
} from "../../services/google-analytics"
import { pixelLogin, pixelLogout } from "../../services/facebook-pixel"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem("wcUser")) {
    return JSON.parse(window.localStorage.getItem("wcUser"))
  }
  return {}
}

export const userRegister = (
  email,
  password,
  first_name = "",
  last_name = "",
  bday = "",
  setLoading = () => {},
  setApiError = () => {},
  callback = () => {}
) => {
  setLoading(true)

  register(email, password, first_name, last_name, bday)
    .then((responseData) => {
      if (responseData.data && responseData.data.status >= 400) {
        let errorMessage = "Something went wrong while trying to register"
        console.log("errorMessage: ", responseData.data)
        setApiError({ header: "Registration invalid", content: errorMessage })
      } else {
        setApiError(false)
      }
      callback()
    })
    .catch(async (error) => {
      console.log("Exception while registering: ", error)
      let errorString = {
        header: "Register failed",
        content: "Register failed, please try again!",
      }
      if (error.message) {
        errorString.content = error.message
      } else {
        console.log("Error Response: ", error)
      }
      setApiError(errorString)
      setLoading(false)
    })
}

export const userLogin = (
  email,
  password,
  setLoading = () => {},
  setApiError = () => {},
  callback = () => {},
  credentialCheckOnly = false
) => {
  setLoading(true)
  login(email, password)
    .then(async (responseData) => {
      // 4xx responses do not throw an exception. We force it here so we can handle them as errors
      if (responseData.data && responseData.data.status >= 400) {
        throw responseData
      } else {
        if (!credentialCheckOnly) {
          setUser(responseData)
          analyticsLogin(
            responseData?.user_email || null,
            responseData?.user_display_name || null
          )
          pixelLogin()
        }
        setApiError(false)
        await callback(responseData)
        setLoading(false)
      }
    })
    .catch(async (error) => {
      console.log("Exception while logging in: ", error)
      let errorString = {
        header: "Login failed",
        content: "Login failed, something went wrong",
      }
      if (error && error.message) {
        error.message = error.message.replace("<strong>ERROR</strong>: ", "")
        if (error.code.includes("incorrect_password")) {
          errorString.content = `${
            error.message.split(" <a")[0]
          } If you're having trouble you can reset your password`
        } else if (error.code.includes("invalid_email")) {
          errorString.content =
            "Invalid email, no account exists with this email address. If you're having trouble you can register a new account"
        } else {
          errorString.content = "Login failed, something went wrong"
        }
      }
      setApiError(errorString)
      setLoading(false)
    })
}

export const setUser = (user) => {
  if (user.token && user.token.includes(".")) {
    // Decode the JWT webtoken from base64
    const tokenPayload = JSON.parse(atob(user.token.split(".")[1]))
    const localStorageUser = { ...user, tokenPayload }
    if (isBrowser())
      window.localStorage.setItem("wcUser", JSON.stringify(localStorageUser))
  } else {
    console.log("Login token format invalid: ", user)
    // eslint-disable-next-line no-throw-literal
    throw "Login token format invalid"
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  let expires = null

  // Check if our token is still valid
  if (isBrowser() && user.token && user.tokenPayload) {
    expires = user.tokenPayload.exp
  }

  if (!expires) {
    return false
  }

  if (expires < Math.floor(new Date() / 1000)) {
    logout()
    return false
  }

  return !!user.token
}

export const logout = () => {
  if (isBrowser()) {
    window.localStorage.setItem("wcUser", JSON.stringify({}))
    analyticsLogout()
    pixelLogout()
  }
}
